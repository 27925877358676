import { ArrowForward } from '@mui/icons-material';
import { useConfig } from '@sortlist-frontend/config';
import { Button } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation';
import { removeStorage } from '@sortlist-frontend/utils';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { useTracker } from '_core/hooks/use-tracker';

import { getProjectData } from '../utils';

type Props = {
  className?: string;
  setIsProjectBannerShown: (show: boolean) => void;
};

export const ProjectBanner: FC<Props> = (props) => {
  const { className, setIsProjectBannerShown } = props;
  const { t } = useTranslation('common');
  const { publicEnv } = useConfig();

  const router = useRouter();
  const { track } = useTracker();

  const goToProject = () => {
    const projectData = getProjectData();
    if (!projectData) return false;

    const { token, projectId } = projectData;

    track('projectReaccessed', {
      projectId,
      pathname: router.pathname,
    });

    const url = `${publicEnv.clientAppUrl}/projects/${projectId}`;
    window.location.href = url;
  };

  return (
    <div className={clsx(className, 'bg-primary-200')} data-testid="project-banner">
      <div className="py-8 text-primary-500 container-lg layout-gt-xs-row layout-align-space-between-center">
        <p className={clsx('small mb-xs-4')}>{t('common:projectBanner.description')}</p>

        <div className="layout-column layout-gt-xs-row layout-align-start-start layout-gt-xs-align-end-center">
          <Button
            size="xxs"
            label={t('common:projectBanner.dontShowAgain')}
            buttonStyle="primary"
            buttonVariant="default"
            className="mr-8 mb-xs-4 underline"
            onClick={() => {
              removeStorage('projectData');
              setIsProjectBannerShown(false);
            }}
            truncate={false}
          />
          <Button
            size="xxs"
            label={t('common:projectBanner.goToProject')}
            buttonStyle="primary"
            buttonVariant="outlined"
            iconRight={<ArrowForward />}
            onClick={goToProject}
            truncate={false}
          />
        </div>
      </div>
    </div>
  );
};
