import { cx } from '@emotion/css';
import { COLORS, useOnClickOutside } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { useMemo, useRef, useState } from 'react';

import { DynamicIcon } from '_components/common/DynamicIcon';
import { PublicApiRepo, useGetLinks } from '_core/repos/public-api.repo';
import { IconNames } from '_core/type-utils';
import { getLocalizedUrl } from '_core/utils/public-links/getLocalizedUrl';
import { customConfig } from '_features/custom/custom.config';
import { getHierarchyLinks } from '_features/home/utils';
import { Links } from '_types/public-api';

import * as S from './styles';

export const SubNav = () => {
  const { t } = useTranslation(customConfig.i18nNamespaces);
  const { data: links } = useGetLinks(PublicApiRepo.publicApiNavBarUrl);

  const hierarchyLinks = useMemo(() => getHierarchyLinks(links as Links, t, 'subnav'), [links, t]);
  const menuDropdownRef = useRef(null);

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number | null>(null);

  useOnClickOutside(menuDropdownRef, () => {
    setSelectedCategoryIndex(null);
  });

  if (hierarchyLinks.length === 0) return null;

  return (
    <div className="hide show-gt-sm" ref={menuDropdownRef} style={{ zIndex: 10, position: 'sticky', top: 0 }}>
      <div className={cx('bg-secondary-100 border-bottom border-secondary-300 border-bottom border-secondary-300')}>
        <div className="container-lg layout-row layout-align-start-center-end gap-x-16" style={{ height: '3rem' }}>
          {hierarchyLinks.map((category, index) => {
            return (
              <S.SubNavItem
                onClick={() => setSelectedCategoryIndex(selectedCategoryIndex === index ? null : index)}
                key={category.name}
                className="px-8 py-12 gap-x-8 layout-row layout-align-center-center"
                style={{
                  borderBottom:
                    selectedCategoryIndex === index
                      ? `3px solid ${COLORS.SECONDARY_900}`
                      : `3px solid ${COLORS.SECONDARY_100}`,
                }}>
                {category.iconName != null ? (
                  <DynamicIcon
                    iconName={category.iconName as IconNames}
                    width={20}
                    height={20}
                    className={cx(selectedCategoryIndex === index ? 'text-secondary-900' : 'text-secondary-500')}
                  />
                ) : null}
                <span
                  className={cx(
                    'small medium',
                    selectedCategoryIndex === index ? 'text-secondary-900' : 'text-secondary-500',
                  )}>
                  {category.name}
                </span>
                {category.links?.map((levelTwo, keyLink) => {
                  return <div key={keyLink}>{levelTwo.anchor}</div>;
                })}
              </S.SubNavItem>
            );
          })}
        </div>
      </div>
      {selectedCategoryIndex != null ? (
        <S.SubNavMenu className="slide-in-top-subnav bg-neutral-100 absolute width-100">
          <div className="container-lg layout-row layout-align-space-between gap-x-32 py-32">
            {hierarchyLinks[selectedCategoryIndex].children.map((category) => {
              return (
                <div className="flex" key={category.name}>
                  <span className="bold lh-2">{category.name}</span>
                  <ul className="layout-column layout-wrap gap-y-16 mt-16 list-reset">
                    {category.links?.map((link) => {
                      return (
                        <li className={cx('layout-row', link.tag == null ? 'lh-4' : null)} key={link.anchor}>
                          <a
                            href={getLocalizedUrl(link)}
                            className="text-secondary-700 layout-row layout-align-center-center small">
                            {link.anchor}
                          </a>
                          {link.tag != null ? (
                            <div className="bg-success-200 text-success-700 rounded-xl px-12 py-4 ml-12 small">
                              {link.tag}
                            </div>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </S.SubNavMenu>
      ) : null}
    </div>
  );
};
